






























































import { Vue, Component } from "vue-property-decorator";
import { AuthData } from "@/store/auth/types";
import { RawLocation } from "vue-router";

interface MenuItem {
  icon: string;
  title: string;
  route: RawLocation;
  exact: boolean;
}

@Component
export default class StudentLayout extends Vue {
  drawer = null as null | boolean;
  title = "";
  currentMenuItem = {};
  menuItems: MenuItem[] = [
    {
      icon: "mdi-monitor-dashboard",
      title: "Eğitimler",
      route: { name: "AdminCourses" },
      exact: true
    },
    {
      icon: "mdi-account-group",
      title: "Kullanıcılar",
      route: { name: "AdminUsers" },
      exact: true
    },
    {
      icon: "mdi-file-tree",
      title: "Faaliyet Grupları",
      route: { name: "AdminActivityGroups" },
      exact: true
    },
    {
      icon: "mdi-calendar-check",
      title: "Faaliyetler",
      route: { name: "AdminActivities" },
      exact: true
    },
    {
      icon: "mdi-format-list-checks",
      title: "Görevler",
      route: { name: "AdminTasks" },
      exact: true
    }
  ];

  goTo(item: MenuItem): void {
    this.currentMenuItem = item;
    this.title = item.title;
    this.$router.push(item.route).catch(() => {
      return false;
    });
  }

  get authData(): AuthData {
    return this.$store.getters["auth/data"];
  }

  get isUserLoggedIn(): boolean {
    return this.$store.getters["auth/isUserLoggedIn"];
  }

  logout(): void {
    this.$store.dispatch("auth/logout");
    this.$router.push({ name: "Home" }).catch(() => {
      return false;
    });
  }
}
